exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-old-tsx": () => import("./../../../src/pages/index.old.tsx" /* webpackChunkName: "component---src-pages-index-old-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-services-ai-solutions-tsx": () => import("./../../../src/pages/services/ai-solutions.tsx" /* webpackChunkName: "component---src-pages-services-ai-solutions-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-software-solutions-tsx": () => import("./../../../src/pages/services/software-solutions.tsx" /* webpackChunkName: "component---src-pages-services-software-solutions-tsx" */)
}

